import React, { useRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import ReCaptcha from "react-google-recaptcha";
import * as SH from "@shared";
import { Row, Container, Col } from "react-bootstrap";
import { preFetch } from "@utils";

const recaptchaAPIKey = `${process.env.GATSBY_GOOGLE_CAPTCHA_SITEKEY}`;
const MAILER_API = `${process.env.GATSBY_MAILER_API}`;

export const ContactForm = () => {
  const { register, handleSubmit, setValue, errors, watch } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [isError, setIsError] = useState(false);
  const formElemRef = useRef<HTMLFormElement>(null);
  const captchaRef = useRef<ReCaptcha>(null);

  useEffect(() => {
    register({ name: "captchaResponse" });
  }, []);

  const preSubmit = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    preFetch();
    handleSubmit(onSubmit)(e);
  };

  const onSubmit = async () => {
    if (captchaRef && captchaRef.current) {
      setIsLoading(true);
      captchaRef.current.reset();
      let token: string | null = "";
      try {
        token = await captchaRef.current.executeAsync();
        await setValue("captchaResponse", token);
      } catch {
        setIsError(true);
        setIsLoading(false);
      }

      const data = watch();

      fetch(MAILER_API, {
        body: JSON.stringify({
          formType: "contact",
          ...data,
        }),
        method: "POST",
      })
        .then((response) => {
          if (response.ok) {
            setIsSend(true);
          } else {
            return Promise.reject(`Http error: ${response.status}`);
          }
        })
        .catch(() => setIsError(true))
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  };

  return (
    <SH.Wrapper.Primary>
      <Container>
        <Row className="justify-content-center mx-0">
          <Col lg="8">
            <form
              ref={formElemRef}
              onSubmit={preSubmit}
              className="row justify-content-between overflow-hidden"
            >
              <Col
                className="flex-column-reverse d-flex flex-column overflow-hidden my-5 px-0"
                lg="5"
              >
                <SH.Input.BaseInput
                  placeholder="required"
                  name="name"
                  id="name"
                  ref={register({ required: true, minLength: 1 })}
                />
                <SH.Input.Label htmlFor="name" isError={errors.name}>
                  {" "}
                  name
                </SH.Input.Label>
              </Col>
              <Col
                className="flex-column-reverse d-flex flex-column overflow-hidden my-5 px-0"
                lg="5"
              >
                <SH.Input.BaseInput
                  placeholder="required"
                  name="email"
                  id="email"
                  ref={register({
                    required: true,
                    minLength: 1,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address",
                    },
                  })}
                />
                <SH.Input.Label
                  htmlFor="email"
                  isError={errors.email}
                  errorMessage={errors.email?.message}
                >
                  email
                </SH.Input.Label>
              </Col>{" "}
              <Col
                className="flex-column-reverse d-flex flex-column overflow-hidden my-5 px-0"
                lg="5"
              >
                <SH.Input.BaseInput
                  placeholder="optional"
                  name="company"
                  ref={register()}
                />
                <SH.Input.Label htmlFor="company" isError={errors.company}>
                  company
                </SH.Input.Label>
              </Col>
              <Col
                className="flex-column-reverse d-flex flex-column overflow-hidden my-5 px-0"
                lg="5"
              >
                <SH.Input.BaseInput
                  type="tel"
                  placeholder="optional"
                  name="phone"
                  id="phone"
                  ref={register()}
                />
                <SH.Input.Label htmlFor="phone" isError={errors.phone}>
                  phone
                </SH.Input.Label>
              </Col>
              <Col
                className="flex-column-reverse d-flex flex-column overflow-hidden my-5 px-0"
                lg="12"
              >
                <SH.Input.TextArea
                  placeholder="required"
                  name="message"
                  id="message"
                  ref={register({
                    required: true,
                    minLength: 20,
                  })}
                />
                <SH.Input.TextAreaLabel
                  htmlFor="message"
                  isError={errors.message}
                >
                  message
                </SH.Input.TextAreaLabel>
              </Col>
              <Col lg="12" className="d-flex justify-content-center mt-5 pb-5">
                {!isLoading && !isSend && (
                  <>
                    {isError ? (
                      <SH.Text.SubTitle3 colorComponent="blueblack">
                        Sorry, something went wrong. <br /> Please use the
                        chatbox.
                      </SH.Text.SubTitle3>
                    ) : (
                      <SH.Button.Base type="submit">
                        send your message
                      </SH.Button.Base>
                    )}
                  </>
                )}
                {isLoading && !isError && <SH.Loader />}
                {isSend && !isError && <SH.DoneBoxAnimated />}
              </Col>
              <ContainerCaptcha>
                <ReCaptcha
                  size="invisible"
                  badge="inline"
                  ref={captchaRef}
                  sitekey={recaptchaAPIKey}
                />
              </ContainerCaptcha>
            </form>
          </Col>
        </Row>
      </Container>
    </SH.Wrapper.Primary>
  );
};

const ContainerCaptcha = styled.div`
  position: fixed;
  width: 0;
  overflow: hidden;
  left: 0;
  bottom: 0;
`;
