import * as React from "react";
import { useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "100%",
  minHeight: "255px",
};

const center = {
  lat: 50.0704821,
  lng: 19.9447941,
};

const GOOGLE_MAPS_KEY = `${process.env.GATSBY_GOOGLE_MAP_SITEKEY}`;

const useGoogleMaps = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_KEY,
  });

  return { containerStyle, isLoaded, center };
};

export default useGoogleMaps;
