import React from "react";
import { graphql } from "gatsby";
import Layout from "@components/Layout";
import { HeroSection } from "@shared";
import { TContact } from "@types";
import * as C from "@components/ContactPage";

const ContactPage = ({ data }: TContact) => {
  const { seo, hero } = data.strapiContactPage;

  return (
    <Layout seo={seo}>
      <HeroSection
        bgHero={{ bgMobile: hero.bgMobile, bgDesktop: hero.bgDesktop }}
        title={hero.title}
        description={hero.description}
      />
      <C.ContactForm></C.ContactForm>
      <C.AddressBox />
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  {
    strapiContactPage {
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicUrl
          }
        }
      }
      hero {
        title
        description
        bgMobile {
          localFile {
            publicUrl
          }
        }
        bgDesktop {
          localFile {
            publicUrl
          }
        }
      }
    }
  }
`;
