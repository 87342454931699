import styled from "styled-components";
import * as SH from "@shared";

export const Separator = styled.div`
  height: 2px;
  margin: 40px 0 50px;
  background: #2d3748;
`;

export const Href = styled.a`
  ${SH.Text.TextBodyCss};
  display: block;
  color: inherit;
  margin-bottom: 1rem;
`;
