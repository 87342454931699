import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as SH from "@shared";
import * as S from "./AdressBox.styles";
import GoogleMapComponent from "./GoogleMaps";

export const AddressBox = () => {
  return (
    <SH.Wrapper.Primary>
      <Container>
        <Row>
          <Col xs="11" className="offset-lg-1">
            <SH.Text.Header2>
              where we make <br /> IT happen?
            </SH.Text.Header2>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg="7">
            <GoogleMapComponent />
          </Col>
          <Col lg="5">
            <SH.Wrapper.Box
              className="mb-0 mx-0 mt-3 mt-lg-0"
              colorComponent="whitegray"
            >
              <Row>
                <Col lg="10" className="offset-lg-1">
                  <SH.Text.Header2>Cracow</SH.Text.Header2>
                </Col>
                <Col lg="10" className="offset-lg-1 mt-4">
                  <SH.Text.Header3 colorComponent="darkblue">
                    address
                  </SH.Text.Header3>
                  <Row>
                    <Col xs="6">
                      <SH.Text.SubTitle3 colorComponent="lightblue">
                        USA
                      </SH.Text.SubTitle3>
                      <SH.Text.TextBody>201 W 5th St 11th Floor</SH.Text.TextBody>
                      <SH.Text.TextBody>TX 78701 Austin, Texas</SH.Text.TextBody>
                      <SH.Text.TextBody>United States</SH.Text.TextBody>
                    </Col>
                    <Col xs="6">
                      <SH.Text.SubTitle3 colorComponent="lightblue">
                        Europe branch
                      </SH.Text.SubTitle3>
                      <SH.Text.TextBody>Szlak 50 Street</SH.Text.TextBody>
                      <SH.Text.TextBody>31-147 Cracow</SH.Text.TextBody>
                      <SH.Text.TextBody>Poland</SH.Text.TextBody>
                    </Col>
                  </Row>
                </Col>
                <Col lg="5" className="offset-lg-1">
                  <S.Separator></S.Separator>
                </Col>
                <Col lg="10" className="offset-lg-1 mt-4">
                  <SH.Text.Header3 colorComponent="darkblue">
                    contact details
                  </SH.Text.Header3>

                  <S.Href href="mailto:info@coderspeak.com">
                    info@CodersPeak.com
                  </S.Href>
                  <S.Href href="tel:+48 690 128 648">+48 690 128 648</S.Href>
                </Col>
              </Row>
            </SH.Wrapper.Box>
          </Col>
        </Row>
      </Container>
    </SH.Wrapper.Primary>
  );
};
