import React from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import useGoogleMaps from "./GoogleMaps.hooks";
import logo from "./logo.svg";
import { styles } from "./GoogleMap.styles";

const GoogleMapComponent = () => {
  const { containerStyle, isLoaded, center } = useGoogleMaps();

  return isLoaded ? (
    <>
      <GoogleMap
        options={{
          styles: styles,
        }}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={16}
      >
        <Marker icon={logo} position={center} />
      </GoogleMap>
    </>
  ) : (
    <>Loading...</>
  );
};

export default GoogleMapComponent;
